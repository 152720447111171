import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr);
var prefix = "/EpisodeRecord"


export default {
    //Get detail episodeID
    getByID: (id) => {
        return httpClient.get(`${prefix}/Get/${id}`)
    },
    updateMedicationNote: (id, bodyReq) => {
        return httpClient.put(`${prefix}/UpdateMedicationNote/${id}`, {}, bodyReq)
    },
}