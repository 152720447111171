<template>
  <Container class="d-print-block">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">
          {{ clinic.clinicName }}
        </span>
        <span class="normal__case w-100">
          {{ clinic.address }}
        </span>
        <span class="normal__case w-100">
          {{ clinic.hotline }}
        </span>
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../../assets/CT_logo.png"
          alt="Logo Cao thang"
        />
      </ImageLogoBox>
    </div>
    <TitlePrescription>
      <span
        v-html="isVnLanguage ? 'Đơn thuốc' : 'Medication Prescription'"
      ></span>
    </TitlePrescription>
    <PatientInfo class="mt-1">
      <div class="d-flex justify-space-between w-100 pl-3 pr-3">
        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Họ tên: " : "Name: " }}
            <span class="upper__case">{{ personalInfo.fullName }}</span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Giới tính: " : "Gender: " }}
            <span class="bold__case">
              {{ personalInfo.genderTypeName }}
            </span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Địa chỉ: " : "Address: " }}
            <span class="bold__case">{{ personalInfo.address }}</span>
          </label>
        </div>

        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Mã hồ sơ: " : "Patient ID: " }}
            <span class="bold__case">{{ personalInfo.patientID }}</span>
          </label>
          <span class="patientInfo__item">
            {{ isVnLanguage ? "Ngày sinh: " : "BirthDay: " }}
            {{ formatDate(personalInfo.birthDate) }}
            <b class="bold__case">
              ({{ isVnLanguage ? "Tuổi: " : "Ages: " }}
              {{
                new Date().getFullYear() -
                new Date(personalInfo.birthDate).getFullYear()
              }})
            </b>
          </span>
        </div>
      </div>
    </PatientInfo>

    <DetailPrescription class="mt-2">
      <DiagnosisBox class="mt-2">
        <div class="d-flex flex-row">
          <div class="d-block text-left diagnosis__title pl-2">Chẩn đoán:</div>
          <div
            v-if="checkDuplicatePrimary(primarys) === 'none'"
            class="d-block diagnosisBox__content"
          >
            <span style="w-100 d-block font-weight-bold">
              Không có chuẩn đoán
            </span>
          </div>
          <div
            v-if="checkDuplicatePrimary(primarys) === 'Duplicate'"
            class="d-block diagnosisBox__content"
          >
            <div class="d-flex flex-column mb-3">
              <span class="w-100 d-block">
                <b class="ml-3"> Cả 2 mắt: </b>
                <b>{{ dataPrimarys.icdDxCode }}</b> -
                {{ dataPrimarys.icdDxDescription }}
              </span>
            </div>
          </div>
          <div
            v-if="checkDuplicatePrimary(primarys) === 'noDuplicate'"
            class="d-block text-left ml-2"
          >
            <div class="d-block diagnosisBox__content">
              <span class="w-100 d-block font-weight-bold"
                >Mắt phải: {{ diagnosisBySide(1) }}</span
              >
              <span class="w-100 d-block font-weight-bold"
                >Mắt trái: {{ diagnosisBySide(2) }}</span
              >
            </div>
          </div>
        </div>
      </DiagnosisBox>

      <MedicineBox v-if="listMedication.length > 0">
        <div
          class="medicineItem"
          v-for="(item, index) in listMedication"
          :key="index"
        >
          <div class="medicineItem__info">
            <span class="order">{{ index + 1 }}/</span>
            <span class="name">{{ item.medicineName }}</span>
            <span class="unit">{{ item.buying }}</span>
          </div>
          <div class="medicineItem__text">
            <span class="note">{{ item.note }}</span>
          </div>
          <div class="medicineItem__text" v-if="item.instruction">
            <span class="note">{{ item.instruction }}</span>
          </div>
        </div>
      </MedicineBox>

      <ShortRefractive>
        <TableContent>
          <thead>
            <tr>
              <th colspan="2"></th>
              <th colspan="3">Mắt phải</th>
              <th colspan="3">Mắt trái</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="2" class="title__prescription">Thị lực xa</td>
              <td class="title__prescription pa-2" sty>Không kính</td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.od.visualAcuity.sc }}</span>
              </td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.os.visualAcuity.sc }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-left pa-2 title__prescription">Có kính</td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.od.newGlass.va }}</span>
              </td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.os.newGlass.va }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="title__prescription">Nhãn áp</td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.od.tonometer.iop }}</span>
              </td>
              <td colspan="3" class="content__prescription">
                <span>{{ optometryConclusion.os.tonometer.iop }}</span>
              </td>
            </tr>
          </tbody>
        </TableContent>
      </ShortRefractive>
      <Footer>
        <v-row justify="space-between" class="w-100">
          <v-col cols="7" class="d-flex flex-column">
            <div class="d-block w-100">
              <span class="note__title">Dặn dò:</span>
              <div class="d-flex w-100 flex-column">
                <span
                  class="text__note"
                  v-for="(item, index) in medicationNote"
                  :key="index"
                  >{{ item }}<br
                /></span>
              </div>
            </div>
            <div class="d-block w-100 mt-2">
              <span class="note__title">Tái khám:</span>
              <div class="d-flex w-100 flex-column">
                <span
                  class="text__note"
                  v-for="(item, index) in dateFollowUp"
                  :key="index"
                >
                  {{ formatFollowUp(item.timeStart, item.note) }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="pa-2">
            <div class="footer__staffHandler">
              <span class="footer__staffHandler__date"
                >Ngày: <b class="bold__case">{{ today }}</b></span
              >
              <span class="footer__staffHandler__role">Bác sĩ</span>
              <div class="footer__staffHandler__signal"></div>
              <span class="footer__staffHandler__name">{{
                lastUserInserting
              }}</span>
            </div>
          </v-col>
          <v-col cols="12">
            <div style="font-size: 10px" class="text-center">
              * Vui lòng mang theo toa thuốc và đơn kính khi đến tái khám
            </div>
          </v-col>
        </v-row>
      </Footer>
    </DetailPrescription>
  </Container>
</template>

<script>
import { awaitAll } from "@/plugins/helper";
import moment from "moment";
import _ from "lodash";
import { MedicineBuyingUnit } from "../Print/config";
import DiagnosisService from "@/services/diagnosis";
import OptometryConclusionService from "@/services/optometryConclusion";
import MedicationService from "@/services/medication";
import EpisodeRecordService from "@/services/episodeRecord";
import BookingService from "@/services/booking";
import PatientService from "@/services/patient";
import BrandService from "@/services/brand";
import i18n from "@/plugins/i18n";

import {
  Container,
  DiagnosisBox,
  ShortRefractive,
  TableContent,
  MedicineBox,
  ClinicDetail,
  ImageLogoBox,
  TitlePrescription,
  PatientInfo,
  Footer,
  DetailPrescription,
} from "./css/style";
export default {
  name: "MedicinePrescription",
  components: {
    Container,
    DiagnosisBox,
    ShortRefractive,
    TableContent,
    MedicineBox,
    ClinicDetail,
    ImageLogoBox,
    TitlePrescription,
    PatientInfo,
    Footer,
    DetailPrescription,
  },
  props: {
    hideRawData: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userName() {
      let user = this.$store.getters.userData;
      return user.fullName;
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
  },
  data: () => ({
    printBlock: true,
    nonePrint: true,
    isVnLanguage: i18n.locale == "vn",
    personalInfo: {},
    clinic: {},
    medicationNote: [],
    dateFollowUp: [],
    listMedication: [],
    primaryDiagnosis: {
      od: [],
      os: [],
    },
    primarys: [],
    // patientID: "",
    optometryConclusion: {
      od: {
        visualAcuity: {
          sc: "",
        },
        newGlass: {
          va: "",
        },
        tonometer: {
          iop: "",
        },
      },
      os: {
        visualAcuity: {
          sc: "",
        },
        newGlass: {
          va: "",
        },
        tonometer: {
          iop: "",
        },
      },
    },
    lastUserInserting: "",
  }),
  created() {
    console.log(this.episodeRecordID);
    this.print();
  },
  methods: {
    async print() {
      await awaitAll([
        this.renderPatientDetail(),
        this.renderClinicDetail(),
        this.getDiagnosis(),
        this.searchingMedication(),
        this.getMedicationNote(),
        this.renderFollowUp(),
        this.getOptometryConclusion(),
      ]);
      window.print();
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatFollowUp(date, note) {
      if (!date) return "";
      if (!note) note = "N/A";
      return `${this.jsUcfirst(
        moment(date).lang("vi").format("dddd")
      )}, Ngày ${moment(date).format("DD/MM/YYYY")} lúc ${moment(date).format(
        "HH:mm"
      )} : ${note}`;
    },
    async getDiagnosis() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      const apiResult = await DiagnosisService.SearchListDiagnosis(
        episodeRecordID,
        1,
        100
      );
      if (apiResult.error) {
        this.$toast.error("Can not retrieve Data!");
        return;
      }
      let listDiagnosis = _.cloneDeep(apiResult.items);

      // parse data
      this.primaryDiagnosis = {
        od: _.filter(listDiagnosis, {
          diagnosisCodePosition: 1,
          diagnosisCode: "Primary",
        }),
        os: _.filter(listDiagnosis, {
          diagnosisCodePosition: 2,
          diagnosisCode: "Primary",
        }),
      };
      this.primarys = apiResult.items.filter(
        (i) => i.diagnosisCode === "Primary"
      );
    },
    diagnosisBySide(side) {
      // 1: OD,
      // 2: OS
      let objectDiagnosis;
      switch (side) {
        case 1:
          objectDiagnosis = this.primaryDiagnosis.od;
          break;
        case 2:
          objectDiagnosis = this.primaryDiagnosis.os;
          break;
        default:
          return "";
      }
      if (objectDiagnosis.length == 0) {
        return "";
      }
      objectDiagnosis = objectDiagnosis[0];
      let result = `${objectDiagnosis.icdDxDescription} (${objectDiagnosis.icdDxCode})`;
      return result;
    },

    async getOptometryConclusion() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      let listConclusion = ["TonoPD", "NewGlass", "VisualAcuity"];
      var result = await OptometryConclusionService.getElementConclusion(
        episodeRecordID,
        listConclusion.join(",")
      );

      if (result.error) {
        this.$toast.error("Can not retrieve Optometry Conclusion");
        return;
      }
      this.optometryConclusion.od = result.od;
      this.optometryConclusion.os = result.os;
      console.log("getOptometryConclusion:" + this.optometryConclusion);
    },

    async searchingMedication() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var listMedication = await MedicationService.search(
        episodeRecordID,
        "",
        1,
        100
      );
      if (listMedication.error) {
        this.$toast.error(listMedication.message);
        return "";
      }
      console.log(listMedication.items);
      let items = _.cloneDeep(listMedication.items);

      items = items.map((i) => {
        let buyingUnitEntry = _.find(MedicineBuyingUnit, { id: i.buyingUnit });

        i.buying = `${i.totalBuyingNumber} ${
          buyingUnitEntry != null ? buyingUnitEntry.vname : ""
        }`;
        return i;
      });
      this.listMedication = items;
      if (this.listMedication.length > 0) {
        let lastRecord = this.listMedication[this.listMedication.length - 1];
        this.lastUserInserting = lastRecord.userPrescribeName;
      }
    },
    async getMedicationNote() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var resultData = await EpisodeRecordService.getByID(episodeRecordID);
      if (resultData.error) {
        console.log(
          "Cannot get medication note at the moment! Please try again later."
        );
        return;
      }
      this.medicationNote = resultData.medicationNote.split(",");
    },
    async renderFollowUp() {
      var patientID = this.$route.params.patientID;
      var apiResult = await BookingService.getBookingByPatient(
        patientID,
        moment().format("YYYY-MM-DD"),
        ""
      );
      if (!apiResult || apiResult.error) {
        console.log(
          "Cannot get follow up at the moment! Please try again later."
        );
        return;
      }
      this.dateFollowUp = apiResult.items;
    },
    async renderPatientDetail() {
      var patientID = this.$route.params.patientID;
      var result = await PatientService.getPatientDetail(patientID);
      if (result.error) {
        console.log("Cannot get patient info. Please try again later!");
        return;
      }
      const { personalInfo } = result;
      this.personalInfo = personalInfo;
      this.personalInfo.genderTypeName = this.personalInfo.genderType - 1;
    },
    async renderClinicDetail() {
      let clinicID = this.$store.getters.userData.clinicID;
      var result = await BrandService.getBrandClinicByID(clinicID);
      if (result.error) {
        console.log("Cannot get clinic info. Please try again later!");
        return;
      }
      this.clinic = result;
    },
    checkDuplicatePrimary(array = []) {
      if (array.length > 1) {
        if (array[0].icdDxID == array[1].icdDxID) {
          this.dataPrimarys = array[0];
          return "Duplicate";
        } else {
          return "noDuplicate";
        }
      } else if (array.length == 1) {
        return "noDuplicate";
      } else if (array.length == 0) {
        return "none";
      }
    },
  },
};
</script>

<style scoped lang ="scss">
@media print {
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }

  .medicineItem__info {
    padding: 1px;
  }

  .medicineItem__note {
    padding: 0px;
    font-size: 10px;
  }
  .order {
    font-size: 12px;
  }
  .name {
    font-size: 12px;
  }
  .unit {
    font-size: 12px;
  }
  td {
    padding: 1px;
    font-size: 10px;
  }
  .content__prescription {
    font-size: 9px;
  }
  .title__prescription {
    font-size: 9px;
  }
  .diagnosisBox__content {
    font-size: 12px;
    margin-left: 5px;
  }
  .footer__content {
    margin-top: 3px;
  }
}
</style>