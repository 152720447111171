import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import AppConstant from '../plugins/constant'
var httpClient = new HttpClient(ApiGateway.Emr);

var prefix = "/Medication"

export default {
  create: (episodeRecordID, bodyReq) => {
    return httpClient.post(`${prefix}/Create/${episodeRecordID}`, {}, bodyReq);
  },
  createList: (episodeRecordID, rewrite, bodyReq) => {
    var query = {
      rewrite
    }
    return httpClient.post(`${prefix}/Create/ListMedicine/${episodeRecordID}`, query, bodyReq);
  },
  search: (episodeRecordID, keySearch, page = 1, limit = AppConstant.DefaultPaginationLimit) => {
    var query = {
      keySearch,
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get(`${prefix}/Search/${episodeRecordID}`, query, headers)
  },
  getByID: (id) => {
    return httpClient.get(`${prefix}/Get/${id}`)
  },
  update: (id, bodyReq) => {
    return httpClient.put(`${prefix}/Update/${id}`, {}, bodyReq)
  },
  delete: (id) => {
    return httpClient.delete(`${prefix}/Delete/${id}`)
  },
}